import { Inject, Injectable } from '@angular/core';
import { Direction } from '@angular/cdk/bidi';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class LangService {
  direction: Direction = null;
  currentLang!: string;

  constructor(
    private _translate: TranslateService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this._setInitLang();
    this.currentLang = this._translate.currentLang;
  }

  private _setInitLang() {
    // Get the default language and direction or set it to english and ltr
    // const savedLanguage = localStorage.getItem('defaultLanguage');
    // this.direction = (localStorage.getItem('dir') as Direction) || 'rtl';

    // this._translate.addLangs(['en', 'ar']);
    // if (savedLanguage) {
    //   this._translate.setDefaultLang(savedLanguage);
    //   this._translate.use(savedLanguage);
    // } else {
    //   this._translate.setDefaultLang('ar');
    //   this._translate.use('ar');
    // }

    this.currentLang = localStorage.getItem('defaultLanguage') || 'ar';
    this.direction = (localStorage.getItem('dir') as Direction) || 'rtl';

    this._translate.setDefaultLang(this.currentLang);
    this._translate.use(this.currentLang);
    let htmlTag = this.document.getElementsByTagName(
      'html'
    )[0] as HTMLHtmlElement;
    htmlTag.dir = this.currentLang === 'ar' ? 'rtl' : 'ltr';
    htmlTag.lang = this.currentLang === 'ar' ? 'ar' : 'en';
    localStorage.setItem('defaultLanguage', this.currentLang);
  }

  useLanguage(language: string) {
    if (language == 'ar') this.direction = 'rtl';
    else this.direction = 'ltr';

    // Set the default language and direction
    localStorage.setItem('dir', this.direction);
    localStorage.setItem('defaultLanguage', language);

    this._translate.use(language);
    this.currentLang = language;

    //  this.currentLang = localStorage.getItem('defaultLanguage') || 'ar';
    //  this._translate.setDefaultLang(this.currentLang);
    //  this._translate.use(this.currentLang);
    let htmlTag = this.document.getElementsByTagName(
      'html'
    )[0] as HTMLHtmlElement;
    htmlTag.dir = this.currentLang === 'ar' ? 'rtl' : 'ltr';
    htmlTag.lang = this.currentLang === 'ar' ? 'ar' : 'en';
    localStorage.setItem('defaultLanguage', this.currentLang);
  }
}
