import {
  Component,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
  AfterViewInit,
  ViewChild,
  Input,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import {
  KortobaaMeetService,
  ConnectToMeetingStatus,
  MeetingEventsListenersService,
  WaitingUser,
  SocketErrors,
  UserMediaService,
  KickReasons,
} from '@kortobaa-front/ng-kmeet-sdk';
import { LangService } from 'src/app/shared/services/lang.service';
import { DataService } from 'src/app/shared/services/data.service';
import { MeetingsService } from 'src/app/meeting/services/meetings.service';
import { UsersService } from 'src/app/auth/services/users.service';
import { ConfigDialogComponent } from 'src/app/meeting/config-dialog/config-dialog.component';
import { CustomSnackbarComponent } from 'src/app/meeting/custom-snackbar/custom-snackbar.component';
import { NotificationsDialogComponent } from 'src/app/meeting/notifications-dialog/notifications-dialog.component';
import { MeetingPassCodeComponent } from 'src/app/meeting/meeting-pass-code/meeting-pass-code.component';
import { HostOptionsDialogComponent } from 'src/app/meeting/host-options-dialog/host-options-dialog.component';
import { TopSnackbarComponent } from 'src/app/meeting/top-snackbar/top-snackbar.component';
import { SettingsDialogComponent } from 'src/app/meeting/settings-dialog/settings-dialog.component';
import { MeetingURLService } from 'src/app/shared/services/meeting-url.service';
import { LeaveMeetingDialogComponent } from '../leave-meeting-dialog/leave-meeting-dialog.component';
import { Direction } from '@angular/cdk/bidi';
import { AllowMediaComponent } from '../allow-media/allow-media.component';

declare var require: any;
const FileSaver = require('file-saver');

@Component({
  selector: 'meeting-root',
  templateUrl: './meeting.component.html',
  styleUrls: ['./meeting.component.scss'],
  providers: [MeetingsService, UsersService],
})
export class MeetingComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('audioMenuTrigger') audioMenuTrigger: MatMenuTrigger;
  @ViewChild('videoMenuTrigger') videoMenuTrigger: MatMenuTrigger;
  showChatBox: boolean = false;
  sideCollapsed: boolean = true;
  showMembersList: boolean = false;
  recordLink = null;
  unreadMessages: boolean = false;
  raisedHand = false;
  meetingToken: string;
  waitingUsers: WaitingUser[] = [];
  showAll: boolean = false;
  //Get our url
  href = new URL(window.location.href);
  showWaitingRoomNotification = true;
  showInviteBox: boolean = true;
  copied: boolean = false;
  //Get name
  name: string; //= this.href.searchParams.get("name");
  //Get key
  key = this.href.searchParams.get('key');
  password: string | null = this.href.searchParams.get('p');

  isAudioMenuOpen = false;
  isVideoMenuOpen = false;

  meetingInfo;

  // private _window: Window;

  snackbar: MatSnackBarRef<any>;
  snackbarMsg: MatSnackBarRef<any>;

  isWeakConnection = false;

  direction: Direction;
  meetingTitle: string;

  @Input('class') panelClass: string;
  first = false;
  isOpen = false;
  counterMsg = 0;
  showRecordBox: boolean = false;

  meetingUrl: string;

  wrongPassword = false;

  newChatAudio = new Audio('../../../assets/audio/chat-audio.wav');
  waitingRoomAudio = new Audio('../../../assets/audio/waiting-room.mp3');
  joinAudio = new Audio('../../../assets/audio/entrance-host.mp3');
  closeMeetingAudio = new Audio('../../../assets/audio/closed-meeting.mp3');
  participantJoinedAudio = new Audio(
    '../../../assets/audio/participant-entered.mp3'
  );

  notPlaying = true;

  constructor(
    public dialog: MatDialog,
    public dataService: DataService,
    public cdRef: ChangeDetectorRef,
    private _translate: TranslateService,
    public kortobaaPkg: KortobaaMeetService,
    private _meetingEventsListeners: MeetingEventsListenersService,
    private _snackBar: MatSnackBar,
    private _router: Router,
    private _route: ActivatedRoute,
    private _meetingService: MeetingsService,
    public _lang: LangService,
    private _meetingURL: MeetingURLService,
    public userMediaService: UserMediaService
  ) {}

  async ngOnInit() {
    this.meetingToken = this._route.snapshot.paramMap.get('token');
    this.direction = this._lang.direction;

    this._meetingService
      .getMeetingDataByUrl('meetings/' + this.meetingToken)
      .subscribe((data: any) => {
        this.meetingInfo = data;
        this.meetingTitle = data.title;
        this.meetingUrl = window.location.href;
      });
    let canOpenVideo = true;
    try {
      // (await this._meetingService
      //   .checkMeetingForNonUser(this.meetingToken)
      //   .toPromise()) as any;
      // this._meetingService
      //   .getMeetingDataByUrl('meetings/' + this.meetingToken)
      //   .subscribe((data) => {
      //     this.meetingInfo = data;
      //   });
    } catch (error) {
      await this.dialog
        .open(NotificationsDialogComponent, {
          data: { msg: 'Invalid meeting ID' },
          width: '26rem',
          direction: this._lang.direction,
          disableClose: true,
        })
        .afterClosed()
        .toPromise();
      return this._router.navigate(['home']);
    }

    this.confirmDialog(canOpenVideo);
  }

  ngAfterViewInit() {
    this.cdRef.detectChanges();
  }

  ngOnDestroy() {
    this.resetMeeting();
    this.kortobaaPkg.waitingScreen = true;
  }

  resetMeeting() {
    //lets close all opened connections
    this.kortobaaPkg.resetMeetingConnections();
    //lets clear meeting info
    this.kortobaaPkg.clearMeeting();

    if (this.snackbar) this.snackbar.dismiss();
  }

  downloadVideo() {
    const pdfName = 'record.mp4';
    FileSaver.saveAs(this.recordLink, pdfName);
  }

  async ready() {
    await this.tryToConnect();
  }

  async tryToConnect() {
    try {
      this._meetingEventsListeners.onHasError(async (error) => {
        if (error.status == SocketErrors.WrongePassword) {
          if (this.wrongPassword) {
            const msg = this._translate.instant('validation.wrong-password');
            this.dataService.notification(msg, true);
          }
          await this.openPasswordDialog();
        } else if (error.status == SocketErrors.MeetingReconnect) {
          //lets reset the meeting retry to connect
          this.resetMeeting();
          await this.tryToConnect();
        } else {
          console.log(error);

          await this.dialog
            .open(NotificationsDialogComponent, {
              data: {
                msg: error['message'],
                status: error.status,
              },
              width: '26rem',
              direction: this._lang.direction,
              disableClose: true,
            })
            .afterClosed()
            .toPromise();
          this.backToHome();
        }
      });

      this._meetingEventsListeners.onChangeLoading((isLoading) => {
        this.dataService.loading = isLoading;
      });

      // mute user snackbar
      this._meetingEventsListeners.onMuteUser((user: any) => {
        if (
          user.userId == this.kortobaaPkg.userId &&
          !this.kortobaaPkg.isHost &&
          user.muted
        ) {
          this._snackBar.openFromComponent(TopSnackbarComponent, {
            duration: 2000,
            data: { type: 'muteUser' },
            direction: this._lang.direction,
            verticalPosition: 'top',
            horizontalPosition: 'center',
            panelClass: ['in-meeting-snackbar'],
          });
        }
      });

      this._meetingEventsListeners.onJoinedParticipant((data: any) => {
        // play for the user not host when he joins meeting
        if (!this.kortobaaPkg.isHost && this.notPlaying) {
          // console.log('user joined');
          this.joinAudio.play();
          this.notPlaying = false;

          // audio duration in milliseconds
          const audioDuration = Math.round(this.joinAudio.duration) * 1000;

          // this sould make it true after the sound ends
          setTimeout(() => {
            this.notPlaying = true;
          }, audioDuration);
        }

        if (
          this.kortobaaPkg.isHost &&
          !this.kortobaaPkg.meetingSettings.isWaiting
        ) {
          this.participantJoinedAudio.play();
          // console.log('no waiting');
        }

        if (this.kortobaaPkg.userId != 0) {
          this.openDialogJoinUsers(true, data);
        }
      });

      this._meetingEventsListeners.onUpdateHost((data) => {
        if (data != null) {
          this.kortobaaPkg.participants.filter((user) => {
            if (data?.hostId === user.id) {
              if (user.host && !this.kortobaaPkg.isHost && !data.isMe) {
                this.first = true;
              } else {
                this.first = false;
              }
            }
          });

          if (!this.first) {
            if (this.kortobaaPkg.isMeetingFinished()) return;
            this.snackbar = this._snackBar.openFromComponent(
              TopSnackbarComponent,
              {
                duration: 2000,
                data: { type: 'host', content: data },
                direction: this._lang.direction,
                verticalPosition: 'top',
                horizontalPosition: 'center',
                panelClass: ['in-meeting-snackbar'],
              }
            );
          }

          if (this.first && this.snackbar) {
            this.snackbar.dismiss();
          }
        }
      });

      this._meetingEventsListeners.onKickedParticipant((reason) => {
        //lets pass reason data to home by shared service
        this.dataService.homeStatus.type = reason;
        //lets tell home page this user is kicked
        this.backToHome();
      });

      this._meetingEventsListeners.OnAddUsersToWaitingArea((waitingUser) => {
        this.waitingUsers = waitingUser;
        // play join audio for admin
        if (this.kortobaaPkg.isHost && waitingUser.length == 0) {
          this.joinAudio.play();
          // console.log('admin joined');
        }

        // play audio if waiting area is disabled
        // else
        // if (
        //   this.kortobaaPkg.isHost &&
        //   !this.kortobaaPkg.meetingSettings.isWaiting
        // ) {
        //   console.log('no waiting');
        //   this.participantJoinedAudio.play();
        // }

        // play audio if waiting area is enabled
        else if (
          this.kortobaaPkg.isHost &&
          this.kortobaaPkg.meetingSettings.isWaiting &&
          waitingUser.length > 0
        ) {
          // console.log('waiting room');
          this.waitingRoomAudio.play();
        }

        // this.showWaitingRoomNotification = true;
      });

      this._meetingEventsListeners.onAddMessage((isMe: boolean) => {
        if (
          !isMe &&
          (!this.showChatBox || (this.showChatBox && this.sideCollapsed))
        ) {
          this.unreadMessages = true;
          this.newChatAudio.play();

          this.snackbarMsg = this._snackBar.openFromComponent(
            TopSnackbarComponent,
            {
              //duration: 2000,
              data: {
                type: 'msg',
                chat: this.kortobaaPkg.chat,
                number: (this.counterMsg += 1),
              },
              direction: this._lang.direction,
              verticalPosition: 'top',
              horizontalPosition: 'center',
              panelClass: ['in-meeting-snackbar'],
            }
          );

          this.snackbarMsg.afterDismissed().subscribe((actionData) => {
            if (actionData.dismissedByAction) {
              this.showChatBox = true;
              this.showMembersList = false;
              this.toggleSideControl(false);
              this.unreadMessages = false;
              this.snackbarMsg = undefined;
              this.counterMsg = 0;
            }
          });
        }
      });

      this._meetingEventsListeners.onFinishMeeting(() => {
        //lets pass reason data to home by shared service
        if (!this.kortobaaPkg.isHost) {
          this.dataService.homeStatus.type = 'ended';
        }
        this.closeMeetingAudio.play();
        // console.log('meeting closed');
        this.backToHome();
      });

      //updaing title event
      this._meetingEventsListeners.onUpdateTitle(() => {
        let message = '';
        if (this._lang.currentLang == 'ar')
          message = 'تم تغيير عنوان الاجتماع بنجاح';
        if (this._lang.currentLang == 'en')
          message = 'Meeting title changed successfully';
        this._snackBar.openFromComponent(CustomSnackbarComponent, {
          duration: 2000,
          data: message,
          direction: this._lang.direction,
          panelClass: ['accent-snackbar', 'control-bar-confirmation'],
        });
      });

      this._meetingEventsListeners.onConnectToMeeting((data) => {
        this.kortobaaPkg.disableRefreshing();

        // If meeting have not started yet, there is not participant
        let participantId =
          data.status == ConnectToMeetingStatus.started
            ? data.data.participantId
            : null;

        // Save meeting info in session storage
        this.dataService.setMeetingInfo({
          roomToken: this.meetingToken,
          userId: participantId,
          userName: this.name,
        });
      });

      this.kortobaaPkg.joinMeeting({
        username: this.dataService.getUserData()?.username ?? this.name,
        photoUrl: this.dataService.getUserData()?.photoUrl ?? null,
        id: this.dataService.getUserData()?.id ?? null,
        token: this.dataService.getUserToken() ?? null,
      });
    } catch (error) {
      console.error(error);
      this.dataService.loading = false;
      await this.dialog
        .open(NotificationsDialogComponent, {
          data: { msg: error.message },
          width: '26rem',
          direction: this._lang.direction,
          disableClose: true,
        })
        .afterClosed()
        .toPromise();
      this.backToHome();
      throw new Error('Error from join meeting ' + error);
    }
  }

  backToHome() {
    // Lets destroy all connections from component before leaving
    this.kortobaaPkg.resetMeetingConnections();
    this._router.navigate(['/home']);
  }

  leaveMeeting() {
    this.backToHome();
  }

  endMeeting() {
    // end meeting function for meeting host
    this.kortobaaPkg.endMeeting();
  }

  showMembersStatus(data) {
    this.showMembersList = data;
    this.showChatBox = false;
    this.sideCollapsed = false;
  }

  chatBoxStatus(data) {
    this.unreadMessages = false;
    this.showChatBox = data;
    this.showMembersList = false;
    this.sideCollapsed = false;
    if (this.snackbarMsg) this.snackbarMsg.dismiss();
  }

  toggleRaiseHand() {
    this.raisedHand = !this.raisedHand;
  }

  /// show notification dialog to meeting member ex: you are the host of meeting now
  showNotification(notification) {
    this.dialog.open(NotificationsDialogComponent, {
      width: '290px',
      direction: this._lang.direction,
      data: {
        notification: notification,
      },
    });
  }

  async recordVideo() {
    // this.dialog
    //   .open(RecordDialogComponent, {
    //     width: '34rem',
    //     direction: this._lang.direction,
    //     data: {},
    //   })
    //   .afterClosed()
    //   .subscribe((start) => {
    //     this.dataService.recordingLoading = true;
    //     if (start) {
    //       setTimeout(() => {
    //         this.dataService.recordingLoading = false;
    //         this.dataService.start();
    //         this.kortobaaPkg.startRecord();
    //         this.showRecordBox = true;
    //       }, 1000);
    //       // const joined = await this.tm.cmd("startRecord", {
    //       //   name: name,
    //       //   sdp: this.sdp
    //       // });
    //     }
    //   });
  }

  pauseRecording() {
    this.dataService.pause();
  }

  resumeRecording() {
    this.dataService.resume();
  }

  async stopRecording() {
    // //reset recording timer
    // this.dataService.stop();
    // this.showRecordBox = false;
    // // let message: string;
    // // if (this._lang.currentLang == 'ar') {
    // //   message = 'سيتم تنزيل تسجيل الاجتماع عند انتهاء الاجتماع.';
    // // } else {
    // //   message = 'Your record will be downloaded when meeing ends.';
    // // }
    // // this._snackBar.openFromComponent(CustomSnackbarComponent, {
    // //   duration: 2000,
    // //   data: message,
    // //   direction: this._lang.direction,
    // //   panelClass: ['accent-snackbar', 'control-bar-confirmation'],
    // // });
    // let successMessage = this._translate.instant(
    //   'meeting-room.stop-recording-successfully'
    // );
    // this.dataService.notification(successMessage);
    // try {
    //   // const joined = await this.tm.cmd("stopRecord", {
    //   //   name: name,
    //   //   sdp: this.sdp
    //   // })
    //   this.kortobaaPkg.stopRecord();
    //   console.log(this.kortobaaPkg.stopRecord());
    //   // this.recordLink = joined.link;
    // } catch (error) {
    //   console.error(error);
    // }
    // // try {
    // //   const joined = await this.tm.cmd("stopRecord", {
    // //     name: name,
    // //     sdp: this.sdp
    // //   })
    // //   this.recordLink = joined.link;
    // // } catch (error) {
    // //   console.error(error);
    // // }
  }

  //open host options dialog
  async openHostOptionsDialog() {
    const dialogRef = await this.dialog
      .open(HostOptionsDialogComponent, {
        width: '500px',
        panelClass: 'host-options-dialog',
        direction: this._lang.direction,
        data: {
          meetingSettings: this.kortobaaPkg.meetingSettings,
        },
      })
      .afterClosed()
      .toPromise();
    if (dialogRef) {
      this.kortobaaPkg.changeMeetingSettings(dialogRef);
    }
  }

  async openPasswordDialog() {
    const dialogData = await this.dialog
      .open(MeetingPassCodeComponent, {
        direction: this._lang.direction,
        width: '500px',
        autoFocus: false,
        disableClose: true,
        data: {
          firstTime: this.wrongPassword,
          password: this.password,
        },
      })
      .afterClosed()
      .toPromise();
    if (!dialogData) return this.backToHome();
    this.kortobaaPkg.setMeetingPassword(dialogData);
    this.wrongPassword = true;
    this.tryToConnect();
  }

  async confirmDialog(canOpenVideo: boolean = true) {
    this.dialog
      .open(ConfigDialogComponent, {
        direction: this._lang.direction,
        panelClass: 'meeting-dialog',
        width: '100%',
        maxWidth: '100vw',
        // minHeight: '31rem',
        autoFocus: false,
        data: {
          meetingToken: this.meetingToken,
          name: this.name,
          canOpenVideo,
          password: this.password,
        },
        disableClose: true,
      })
      .afterClosed()
      .subscribe(async (dialogData) => {
        if (!dialogData) return;
        this.kortobaaPkg.setMeetingEnv(
          this.meetingToken,
          dialogData.protocolType
        );
        this.kortobaaPkg.updateDevicesBeforeMeeting();
        this.name = dialogData.name;
        await this.ready();
      });
  }

  // Open settings dialog
  async openSettingsDialog(settingsType: any) {
    // To prevent user open settings dialog from code
    //if (settingsType == 'video' && !this.kortobaaPkg.hasVideo) return;

    // Close audio and video menus
    // if (settingsType == 'audio') this.audioMenuTrigger.closeMenu();
    // if (settingsType == 'video') this.videoMenuTrigger.closeMenu();

    this.dialog
      .open(SettingsDialogComponent, {
        panelClass: 'settings-dialog',
        maxWidth: '90vw',
        direction: this._lang.direction,
        data: {
          viewOptions: this.kortobaaPkg.viewOptions,
          host: this.kortobaaPkg.isHost,
          hasVideo: this.kortobaaPkg.hasVideo,
          hasAudio: this.kortobaaPkg.hasAudio,
          meetingSettings: this.kortobaaPkg.meetingSettings,
          settingsType,
        },
        disableClose: true,
      })
      .afterClosed()
      .subscribe(async (dialogData) => {
        if (!dialogData) return;
        if (dialogData.video && !this.userMediaService.isVideoDisabled)
          this.userMediaService.videoDeviceId = dialogData.video;
        if (dialogData.audio && !this.userMediaService.isInputDisabled)
          this.userMediaService.audioDeviceId = dialogData.audio;
        if (dialogData.output && !this.userMediaService.isOuputDisabled)
          this.userMediaService.outputDeviceId = dialogData.output;
        this.kortobaaPkg.updateMeetingSettings(dialogData);
      });
  }

  //accept waiting room member
  acceptMemeber(userId: string, isAccepted: boolean) {
    this.kortobaaPkg.acceptOneOnWaitingArea({
      isAccepted,
      userId,
    });
  }

  toggleSideControl(status: boolean) {
    this.sideCollapsed = status;
    if (this.showChatBox) this.counterMsg = 0;
  }

  copyMeetingUrl(meetingToken: string) {
    if (this.copied) return;
    this.copied = true;
    this._meetingURL.copyMeetingUrl(meetingToken);
    setTimeout(() => {
      this.copied = false;
    }, 3000);
  }

  /// leave meeting dialog
  leaveMeetingDialog() {
    const dialog = this.dialog.open(LeaveMeetingDialogComponent, {
      width: '26rem',
      direction: this._lang.direction,
      data: {},
    });
  }

  openDialogJoinUsers(e: any, data?: any) {
    this.isOpen = e;
    this.snackbar = this._snackBar.openFromComponent(TopSnackbarComponent, {
      duration: 2000,
      data: {
        type: 'user',
        // content:
        //   this.waitingUsers.length > 0
        //     ? this.waitingUsers
        //     : this.kortobaaPkg.participants,
        content: data,
      },
      direction: this._lang.direction,
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: ['in-meeting-snackbar'],
    });

    this.snackbar.afterDismissed().subscribe((actionData) => {
      if (actionData.dismissedByAction) {
        this.isOpen = false;
        this.snackbar = undefined;
      }
    });
  }

  noPermission() {
    let dialogRef: MatDialogRef<AllowMediaComponent, any>;

    if (
      !this.userMediaService.audioStream ||
      !this.userMediaService.videoStream
    ) {
      // Show an allow media dialog for user if his microphone or camera are blocked instead of browser alert
      dialogRef = this.dialog.open(AllowMediaComponent, {
        data: {
          title:
            !this.userMediaService.videoStream &&
            !this.userMediaService.audioStream
              ? 'cam-voice-off'
              : !this.userMediaService.videoStream
              ? 'cam-off'
              : !this.userMediaService.audioStream
              ? 'mic-off'
              : 'cam-voice-off',
          msg:
            !this.userMediaService.audioStream &&
            !this.userMediaService.videoStream
              ? 'open-cam-voice'
              : !this.userMediaService.videoStream
              ? 'open-cam'
              : !this.userMediaService.audioStream
              ? 'open-mic'
              : 'open-cam-voice',
        },
        width: '34rem',
        direction: this._lang.direction,
        disableClose: true,
        autoFocus: false,
      });
    }
  }
}
