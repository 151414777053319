<!-- open dialog download app if join from mobile or tablet -->
<div *ngIf="openAppUi" class="open-app">
  <div class="dialog-content">
    <div class="dialog-header">
      <div class="header-title">
        <a
          class="mobile-close-btn"
          [ngClass]="{ disabled: !userMediaService.isStreamReady }"
          (click)="onNoClick()"
        >
          <mat-icon>close</mat-icon>
        </a>
        <h4>
          {{ "meeting-room.please-continue-through-the-app-title" | translate }}
        </h4>
      </div>
    </div>
    <p class="subtitle1">
      {{ "meeting-room.please-continue-through-the-app-info" | translate }}
    </p>
    <div class="actions-btn">
      <button mat-flat-button color="primary" (click)="downloadApp()">
        {{ "meeting-room.download" | translate }}
      </button>
      <button mat-stroked-button color="primary" (click)="openApp()">
        {{ "meeting-room.open" | translate }}
      </button>
    </div>

    <!--<div class="have-app">
      <p>
        {{ "meeting-room.have-app" | translate }}
      </p>
      <button mat-stroked-button color="primary" (click)="openApp()">
        {{ "meeting-room.open" | translate }}
      </button>
    </div>-->
  </div>
</div>
<!-- end dialog download app if join from mobile or tablet -->

<div
  *ngIf="browserCompatibility && !openAppUi && loading.getData"
  style="height: 225px"
>
  <div style="width: 50px; display: block; margin: 0 auto; margin-top: 125px">
    <mat-spinner diameter="50"></mat-spinner>
  </div>
</div>

<div *ngIf="!loading.getData">
  <div
    *ngIf="
      browserCompatibility && !openAppUi && !loading.getData && !isUDPConnected
    "
    class="compatibility-warning"
  >
    <div class="firewall">
      <img src="assets/images/Firewall.webp" alt="firewall" />

      <label class="subtitle1">{{
        "meeting-room.connectivity_error" | translate
      }}</label>
      <p class="bodytext2">
        {{ "meeting-room.incomplete-requirments" | translate }}
      </p>

      <button
        mat-flat-button
        color="primary"
        class="main-btn subtitle1"
        (click)="onNoClick()"
      >
        {{ "meeting-room.back-home" | translate }}
      </button>
    </div>
  </div>

  <div
    *ngIf="!browserCompatibility && !openAppUi"
    class="compatibility-warning"
  >
    <div class="dialog-header">
      <a
        class="adjust-link"
        [ngClass]="{ disabled: !userMediaService.isStreamReady }"
        (click)="onNoClick()"
      >
        <mat-icon>close</mat-icon>
      </a>

      <h3 class="subtitle1">
        {{ "meeting-room.browser-error" | translate }}
      </h3>
    </div>

    <img src="assets/images/browser-error.svg" alt="browser error" />

    <p class="subtitle2">
      {{ "meeting-room.compatibility-warning" | translate }}
    </p>
    <p class="subtitle2">
      {{ "meeting-room.apple-devices-cannot-join" | translate }}
    </p>
  </div>

  <!-- dialog-header -->
  <div
    class="close-btn"
    *ngIf="!guestLoginForm && !guestRestorePassForm && !openAppUi"
  >
    <button
      mat-raised-button
      color="primary"
      class="close"
      [ngClass]="{
        dirLtr: _lang.direction == 'ltr'
      }"
      (click)="onNoClick()"
    >
      <span>{{ "account.close" | translate }}</span>
    </button>
  </div>

  <div
    class="dialog-header"
    *ngIf="
      !guestLoginForm &&
      !guestRestorePassForm &&
      !guestNewPassForm &&
      !otpConfirmForm &&
      browserCompatibility &&
      !openAppUi &&
      !loading.getData &&
      isUDPConnected
    "
  >
    <p class="header-content">
      <span class="ready-join">
        {{ "meeting-room.ready-join" | translate }}
      </span>
      <!-- <span *ngIf="data.name" class="guest-name"
      >{{ data.name }} {{ "meeting-room.in-meeting" | translate }}</span
    > -->
      <span class="guest-name">{{ meetingTitle }}</span>
    </p>
  </div>

  <form
    class="data-form"
    *ngIf="
      !guestLoginForm &&
      !guestRestorePassForm &&
      !guestNewPassForm &&
      !otpConfirmForm &&
      browserCompatibility &&
      !openAppUi &&
      !loading.getData &&
      isUDPConnected
    "
  >
    <!-- <mat-form-field
    class="bodytext-normal"
    *ngIf="!data.name"
    appearance="fill"
    color="primary"
    block
  >
    <mat-label>
      {{ "meeting-room.type-your-name-to-join" | translate }}
    </mat-label>
    <input
      type="text"
      class="low-opacity-placeholder"
      [(ngModel)]="guestName"
      [ngModelOptions]="{ standalone: true }"
      matInput
      required
    />
    <mat-error>{{
      "validation.your-name-required-to-join" | translate
    }}</mat-error>
  </mat-form-field> -->

    <div class="preview-container">
      <div class="video-preview">
        <!-- <mat-icon
        *ngIf="!userMediaService.withVideoAccept"
        aria-hidden="false"
        aria-label="video off"
        >videocam_off</mat-icon
      > -->
        <div class="user-data" *ngIf="!userMediaService.withVideoAccept">
          <div class="user-img-container">
            <img
              [src]="
                data.name
                  ? dataService.getUserData()?.photoUrl
                  : '../../../assets/images/Profile pic.png'
              "
              ployfillImg
              [alt]="data.name"
            />
          </div>
          <!-- <span>{{ dataService.getUserData()?.username }}</span> -->
        </div>
        <video
          *ngIf="userMediaService.withVideoAccept"
          [srcObject]="userMediaService.videoStream"
          autoplay
          [muted]="true"
        ></video>
      </div>

      <div class="slide-toggle-container">
        <div class="media-options">
          <div
            class="item"
            cdkScrollable
            matTooltipClass="tooltip-control"
            #tooltip="matTooltip"
            [matTooltip]="
              userMediaService.isInputDisabled
                ? ('meeting-room.show-more' | translate)
                : ((!userMediaService.isInputDisabled
                    ? 'meeting-room.mute-audio'
                    : 'meeting-room.unmute-audio'
                  ) | translate)
            "
            matTooltipPosition="above"
            (click)="userMediaService.isInputDisabled ? checkPermission() : ''"

          >
            <mat-slide-toggle
              color="primary"
              class="custom-slide-toggle"
              [ngClass]="{
                on: userMediaService.withAudioAccept,
                pending: userMediaService.isInputDisabled
              }"
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="userMediaService.withAudioAccept"
              [disabled]="userMediaService.isInputDisabled"
              (change)="toggle('audio')"
            >
              <img
                *ngIf="userMediaService.isInputDisabled"
                src="../../../assets/images/icons/permission-info.svg"
              />
            </mat-slide-toggle>
            <mat-icon>
              {{
                userMediaService.withAudioAccept &&
                !userMediaService.isInputDisabled
                  ? "mic_on"
                  : "mic_off"
              }}
            </mat-icon>
          </div>

          <div
            class="item"
            cdkScrollable
            matTooltipClass="tooltip-control"
            #tooltip="matTooltip"
            [matTooltip]="
              userMediaService.isVideoDisabled
                ? ('meeting-room.show-more' | translate)
                : ((!userMediaService.isVideoDisabled
                    ? 'meeting-room.stop-video'
                    : 'meeting-room.start-video'
                  ) | translate)
            "
            matTooltipPosition="above"
            (click)="userMediaService.isVideoDisabled ? checkPermission() : ''"
          >
            <mat-slide-toggle
              color="primary"
              class="custom-slide-toggle"
              [ngClass]="{
                on: userMediaService.withVideoAccept,
                pending: userMediaService.isVideoDisabled
              }"
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="userMediaService.withVideoAccept"
              (change)="toggle('video')"
              [disabled]="userMediaService.isVideoDisabled"
            >
              <img
                *ngIf="userMediaService.isVideoDisabled"
                src="../../../assets/images/icons/permission-info.svg"
              />
            </mat-slide-toggle>

            <mat-icon>
              {{
                userMediaService.withVideoAccept &&
                !userMediaService.isVideoDisabled
                  ? "videocam_on"
                  : "videocam_off"
              }}
            </mat-icon>
          </div>
        </div>

        <div class="media-options">
          <div class="select-option">
            <mat-label>
              {{ "create-meeting.mic-title" | translate }}
            </mat-label>
            <mat-form-field class="bodytext-normal" appearance="fill">
              <mat-select
                [value]="'0'"
                *ngIf="
                  !userMediaService?.audioDevicesList?.length ||
                  userMediaService?.isInputDisabled
                "
              >
                <mat-option
                  *ngIf="!userMediaService?.isInputDisabled"
                  class="bodytext-normal"
                  value="0"
                  selected="true"
                >
                  {{ "settings.default-input" | translate }}
                </mat-option>
                <mat-option
                  *ngIf="userMediaService?.isInputDisabled"
                  class="bodytext-normal"
                  value="0"
                  selected="true"
                >
                  {{ "settings.input-disabled-settings" | translate }}
                </mat-option>
              </mat-select>
              <mat-select
                [(ngModel)]="userMediaService.audioDeviceId"
                [ngModelOptions]="{ standalone: true }"
                (selectionChange)="changeDevice('audio')"
                [disabled]="userMediaService.isInputDisabled"
                (click)="
                  userMediaService.isInputDisabled ? checkPermission() : ''
                "
                *ngIf="
                  userMediaService?.audioDevicesList?.length &&
                  !userMediaService?.isInputDisabled
                "
              >
                <mat-option
                  *ngFor="let device of userMediaService.audioDevicesList"
                  [value]="device.deviceId"
                >
                  {{ device.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="select-option">
            <mat-label>
              {{ "create-meeting.speaker-title" | translate }}
            </mat-label>
            <mat-form-field class="bodytext-normal" appearance="fill">
              <mat-select
                [value]="'0'"
                *ngIf="
                  !userMediaService?.outputDevicesList?.length ||
                  userMediaService?.isOuputDisabled
                "
              >
                <mat-option
                  *ngIf="!userMediaService?.isOuputDisabled"
                  class="bodytext-normal"
                  value="0"
                  selected="true"
                >
                  {{ "settings.default-speaker" | translate }}
                </mat-option>
                <mat-option
                  *ngIf="userMediaService?.isOuputDisabled"
                  class="bodytext-normal"
                  value="0"
                  selected="true"
                >
                  {{ "settings.speaker-disabled-settings" | translate }}
                </mat-option>
              </mat-select>
              <mat-select
                [(ngModel)]="userMediaService.outputDeviceId"
                [ngModelOptions]="{ standalone: true }"
                (selectionChange)="changeDevice('output')"
                [disabled]="userMediaService.isOuputDisabled"
                (click)="
                  userMediaService.isOuputDisabled ? checkPermission() : ''
                "
                *ngIf="
                  userMediaService?.outputDevicesList?.length &&
                  !userMediaService?.isOuputDisabled
                "
              >
                <mat-option
                  *ngFor="let device of userMediaService.outputDevicesList"
                  [value]="device.deviceId"
                >
                  {{ device.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="select-option">
            <mat-label>
              {{ "create-meeting.video-title" | translate }}
            </mat-label>
            <mat-form-field class="bodytext-normal" appearance="fill">
              <mat-select
                [value]="'0'"
                *ngIf="
                  !userMediaService?.videoDevicesList?.length ||
                  userMediaService?.isVideoDisabled
                "
              >
                <mat-option
                  *ngIf="!userMediaService?.isVideoDisabled"
                  class="bodytext-normal"
                  value="0"
                  selected="true"
                >
                  {{ "settings.default-video" | translate }}
                </mat-option>
                <mat-option
                  *ngIf="userMediaService?.isVideoDisabled"
                  class="bodytext-normal"
                  value="0"
                  selected="true"
                >
                  {{ "settings.video-disabled-settings" | translate }}
                </mat-option>
              </mat-select>
              <mat-select
                [(ngModel)]="userMediaService.videoDeviceId"
                [ngModelOptions]="{ standalone: true }"
                (click)="
                  userMediaService.isVideoDisabled ? checkPermission() : ''
                "
                [disabled]="userMediaService.isVideoDisabled"
                (selectionChange)="
                  !userMediaService.isVideoDisabled ? changeDevice('video') : ''
                "
                *ngIf="
                  userMediaService?.videoDevicesList?.length &&
                  !userMediaService?.isVideoDisabled
                "
              >
                <mat-option
                  *ngFor="let device of userMediaService.videoDevicesList"
                  [value]="device.deviceId"
                >
                  {{ device.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>

    <span *ngIf="data.name" class="join-as"
      >{{ "meeting-room.join-as" | translate }} {{ data.name }}</span
    >

    <div *ngIf="!data.name" class="form-input">
      <mat-label>
        {{ "meeting-room.type-your-name-to-join" | translate }}
      </mat-label>
      <mat-form-field
        class="bodytext-normal"
        appearance="fill"
        color="primary"
        block
      >
        <input
          type="text"
          class="low-opacity-placeholder"
          [(ngModel)]="guestName"
          [ngModelOptions]="{ standalone: true }"
          [pattern]="confirmPattern"
          minlength="1"
          maxlength="25"
          matInput
          required
          autocomplete="off"
        />
        <mat-error>{{
          "validation.your-name-required-to-join" | translate
        }}</mat-error>
      </mat-form-field>
    </div>

    <div class="form-footer">
      <p *ngIf="!data.name" class="subtitle1 text-center login-link">
        {{ "meeting-room.If-you-have-an-account" | translate }}
        <a (click)="openGuestLoginForm()" class="headline6">{{
          "meeting-room.login" | translate
        }}</a>
      </p>

      <button
        mat-raised-button
        color="primary"
        class="block main-btn button18"
        (click)="JoinSumbit()"
        [disabled]="
          (!data.name && !guestName) ||
          (guestName && !confirmPattern.test(guestName)) ||
          (userMediaService.audioStream &&
            !userMediaService.isAudioStreamReady) ||
          (userMediaService.videoStream && !userMediaService.isVideoStreamReady)
        "
      >
        <span>{{
          (this.kortobaaPkg.isHost ? "meeting-now" : "join-now") | translate
        }}</span>
      </button>
      <!-- <button
      mat-raised-button
      color="primary"
      class="close"
      [ngClass]="{ disabled: !userMediaService.isStreamReady }"
      (click)="onNoClick()"
    >
      <span>{{ "account.close" | translate }}</span>
    </button> -->
    </div>
  </form>

  <!-- guest login form -->
  <div
    class="login-form"
    *ngIf="
      guestLoginForm &&
      browserCompatibility &&
      !openAppUi &&
      !loading.getData &&
      isUDPConnected
    "
  >
    <form #loginForm="ngForm" class="">
      <a (click)="closeGuestLoginForm()" class="back-link bodytext2">
        <mat-icon>arrow_back</mat-icon>
      </a>

      <div class="form-header">
        <img src="assets/images/logo-icon.png" alt="Halameet" />
        <h3 class="headline2">
          {{ "login.login" | translate }}
        </h3>
      </div>
      <p class="error" *ngIf="errorNotification.length && !waitingTime">
        {{ "errors." + errorNotification | translate }}
        <span class="error" *ngIf="attempts > 0"
          ><br />{{
            "errors." + attempText | translate : { attempts: attempts }
          }}</span
        >
      </p>
      <p class="error" *ngIf="errorNotification.length && waitingTime">
        {{ "errors." + errorNotification | translate : { time: waitingTime } }}
      </p>

      <div class="form-input">
        <mat-label
          >{{ "login.email" | translate }}
          <span class="label__required">*</span>
        </mat-label>

        <mat-form-field
          class="bodytext-normal"
          block
          appearance="fill"
          color="primary"
          [ngClass]="{ invalid: email.touched && email.errors }"
        >
          <input
            type="email"
            name="email"
            #email="ngModel"
            [(ngModel)]="userData.email"
            matInput
            required
            pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
            inputmode="email"
          />
        </mat-form-field>
        <mat-error
          *ngIf="
            loginForm.form.controls['email']?.touched &&
            loginForm.form.controls['email'].errors
          "
          >{{ "validation.email-not-valid" | translate }}</mat-error
        >
      </div>
      <div class="form-input">
        <mat-label
          >{{ "login.password" | translate }}
          <span class="label__required">*</span>
        </mat-label>

        <mat-form-field
          class="bodytext-normal"
          block
          appearance="fill"
          color="primary"
          [ngClass]="{ invalid: password.touched && password.errors }"
        >
          <input
            [type]="hide ? 'password' : 'text'"
            name="password"
            #password="ngModel"
            [(ngModel)]="userData.password"
            matInput
            required
          />

          <button
            class="toggle-pass bodytext2"
            type="button"
            mat-icon-button
            matSuffix
            (click)="hide = !hide"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hide"
          >
            <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
          </button>
        </mat-form-field>
        <mat-error
          *ngIf="
            loginForm.form.controls['password']?.touched &&
            loginForm.form.controls['password'].errors
          "
          >{{ "validation.pass-not-correct" | translate }}</mat-error
        >
      </div>

      <mat-checkbox
        color="primary"
        class="subtitle2"
        name="rememberMe"
        #rememberMe="ngModel"
        [(ngModel)]="userData.rememberMe"
      >
        {{ "login.remember-me" | translate }}
      </mat-checkbox>
      <!-- <div class="login-footer">
    <button
      type="submit"
      [disabled]="!loginForm.form.valid"
      mat-raised-button
      color="primary"
      [loading]="loading.login"
      (click)="login()"
      class="block main-btn button18"
    >
      {{ "login.login" | translate }}
    </button>
  </div> -->

      <div class="form-footer">
        <button
          type="submit"
          [disabled]="!loginForm.form.valid"
          mat-raised-button
          color="primary"
          [loading]="loading.login"
          (click)="login()"
          class="block main-btn button18"
        >
          {{ "login.login" | translate }}
        </button>

        <div class="apps_container">
          <div class="apps_container-or">
            {{ "login.or" | translate }}
          </div>
          <div class="apps">
            <div class="apps-item">
              <figure>
                <img src="../../../assets/images/apple-login.svg" alt="apple" />
              </figure>
            </div>
            <div class="apps-item">
              <figure>
                <img
                  src="../../../assets/images/google-login.svg"
                  alt="google"
                />
              </figure>
            </div>
          </div>
        </div>

        <p class="subtitle1">
          {{ "login.dont-have-account" | translate }}
          <a class="headline6" (click)="openGuestRestorePassForm()">
            {{ "login.sign-up" | translate }}
          </a>
        </p>

        <!-- <a class="headline6" (click)="resetPassword()">
      {{ "login.forgot-my-pass" | translate }}
    </a> -->
      </div>
    </form>
  </div>

  <!-- guest restore password form -->
  <!-- <form
  class="login-form"
>
  <a (click)="closeGuestRestorePassForm()" class="back-link bodytext2">
    <mat-icon>arrow_back</mat-icon>
  </a>

  <label class="headline2">{{ "login.restoe-password" | translate }}</label>
  <p class="bodytext2">{{ "login.type-your-account-email" | translate }}</p>

  <mat-form-field class="bodytext-normal" appearance="fill">
    <input
      type="email"
      placeholder="{{ 'login.email' | translate }}"
      [(ngModel)]="userData.email"
      [ngModelOptions]="{ standalone: true }"
      matInput
      required
    />
    <mat-error>{{ "validation.email-not-valid" | translate }}</mat-error>
  </mat-form-field>
  <div class="login-footer">
    <button
      mat-raised-button
      color="primary"
      class="block main-btn button18"
      (click)="sendRestoreCode()"
    >
      {{ "login.send" | translate }}
    </button>
    <p>
      {{ "login.didnt-receive-mail" | translate }}
      <a routerLink="/signup">{{ "login.resend" | translate }}</a>
    </p>
  </div>
</form> -->

  <div
    class="login-form"
    *ngIf="
      guestRestorePassForm &&
      browserCompatibility &&
      !openAppUi &&
      !loading.getData &&
      isUDPConnected
    "
  >
    <form #signUpForm="ngForm">
      <a (click)="closeGuestRestorePassForm()" class="back-link bodytext2">
        <mat-icon>arrow_back</mat-icon>
      </a>

      <div class="form-header">
        <img src="assets//images/logo-icon.png" alt="Halameet" />
        <h3 class="headline2">
          {{ "login.new-sign-up" | translate }}
        </h3>
      </div>
      <p class="error" *ngIf="errorNotification.length && !waitingTime">
        {{ "errors." + errorNotification | translate }}
        <span class="error" *ngIf="attempts > 0"
          ><br />{{
            "errors." + attempText | translate : { attempts: attempts }
          }}</span
        >
      </p>
      <p class="error" *ngIf="errorNotification.length && waitingTime">
        {{ "errors." + errorNotification | translate : { time: waitingTime } }}
      </p>

      <div class="custom-scroll">
        <div class="form-input">
          <mat-label
            >{{ "login.user-name" | translate }}
            <span class="label__required">*</span>
          </mat-label>
          <mat-form-field
            class="bodytext-normal"
            block
            appearance="fill"
            color="primary"
            [ngClass]="{ invalid: firstName.touched && firstName.errors }"
          >
            <input
              type="text"
              name="firstName"
              #firstName="ngModel"
              [(ngModel)]="registerData.additionalData.firstName"
              pattern="([a-zA-Z',.-]+( [a-zA-Z',.-]+)*){2,30}"
              minlength="2"
              maxlength="30"
              matInput
              required
              autocomplete="off"
            />
            <!-- <mat-error>{{
            "validation.user-name-required" | translate
          }}</mat-error> -->
          </mat-form-field>
          <small
            class="mat-error"
            *ngIf="
              !firstName?.valid &&
              firstName.errors &&
              firstName.touched &&
              (firstName.errors['required'] || firstName.errors['pattern'])
            "
          >
            {{ "validation.user-name-required" | translate }}
          </small>
        </div>
        <!-- email -->

        <div class="form-input">
          <mat-label
            >{{ "login.email" | translate }}
            <span class="label__required">*</span>
          </mat-label>

          <mat-form-field
            class="bodytext-normal"
            block
            appearance="fill"
            color="primary"
            [ngClass]="{ invalid: email.touched && email.errors }"
          >
            <input
              type="email"
              name="email"
              #email="ngModel"
              [(ngModel)]="registerData.email"
              matInput
              required
              pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
              inputmode="email"
              autocomplete="off"
            />
            <!-- <mat-error>{{
            "validation.email-not-valid" | translate
          }}</mat-error> -->
          </mat-form-field>
          <small
            class="mat-error"
            *ngIf="
              !email?.valid &&
              email.errors &&
              email.touched &&
              (email.errors['pattern'] || email.errors['required'])
            "
          >
            {{ "validation.email-not-valid" | translate }}
          </small>
        </div>
        <!-- password -->
        <div class="form-input">
          <mat-label
            >{{ "login.password" | translate }}
            <span class="label__required">*</span>

            <div class="tooltip">
              <mat-icon class="material-icons-outlined info"> info</mat-icon>
              <span
                class="tooltiptext"
                [ngStyle]="{
                  visibility: wrongPassCriteria ? 'visible' : ''
                }"
              >
                <span class="custom-tooltip">
                  {{ "tooltip.password-criteria" | translate }}
                </span>
                <br />
                <mat-icon class="close-icon" *ngIf="!passwordError.length"
                  >close</mat-icon
                >
                <mat-icon class="success-icon" *ngIf="passwordError.length"
                  >check</mat-icon
                >
                {{ "validation.pass-char" | translate }}
                <br />
                <mat-icon class="close-icon" *ngIf="!passwordError.english"
                  >close</mat-icon
                >
                <mat-icon class="success-icon" *ngIf="passwordError.english"
                  >check</mat-icon
                >
                {{ "validation.pass-eng" | translate }}
                <br />
                <mat-icon class="close-icon" *ngIf="!passwordError.number"
                  >close</mat-icon
                >
                <mat-icon class="success-icon" *ngIf="passwordError.number"
                  >check</mat-icon
                >
                {{ "validation.pass-num" | translate }}
                <br />
                <mat-icon class="close-icon" *ngIf="!passwordError.special"
                  >close</mat-icon
                >
                <mat-icon class="success-icon" *ngIf="passwordError.special"
                  >check</mat-icon
                >
                {{ "validation.pass-special" | translate }}
                <br />
                <mat-icon class="close-icon" *ngIf="!passwordError.upper"
                  >close</mat-icon
                >
                <mat-icon class="success-icon" *ngIf="passwordError.upper"
                  >check</mat-icon
                >
                {{ "validation.pass-upper" | translate }}
              </span>
            </div>
          </mat-label>
          <mat-form-field
            class="bodytext-normal"
            block
            appearance="fill"
            color="primary"
            [ngClass]="{ invalid: password.touched && password.errors }"
          >
            <input
              [type]="hideConfirm ? 'password' : 'text'"
              name="password"
              #password="ngModel"
              [(ngModel)]="registerData.password"
              minlength="8"
              maxlength="50"
              matInput
              pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[_+#?!@$%^&*-]).{8,}$"
              required
              autocomplete="off"
              (capsLock)="capsOn = $event"
              (blur)="
                !password?.valid && password.touched
                  ? (wrongPassCriteria = true)
                  : (wrongPassCriteria = false)
              "
              (focus)="wrongPassCriteria = false"
              (input)="checkPasswordPattern(registerData.password)"
            />
            <!-- <mat-error>{{
            "validation.pass-not-correct" | translate
          }}</mat-error> -->
            <button
              class="toggle-pass bodytext2"
              type="button"
              mat-icon-button
              matSuffix
              (click)="hideConfirm = !hideConfirm"
              [attr.aria-label]="'Hide password'"
              [attr.aria-pressed]="hideConfirm"
            >
              <mat-icon class="material-icons-outlined">{{
                hideConfirm ? "visibility_off" : "visibility"
              }}</mat-icon>
            </button>
          </mat-form-field>
          <small
            class="mat-error"
            *ngIf="
              !password?.valid &&
              password.touched &&
              password.errors &&
              password.errors['required']
            "
          >
            {{ "validation.pass-not-correct" | translate }}
          </small>
          <small
            class="mat-error"
            *ngIf="
              !password?.valid &&
              password.touched &&
              password.errors &&
              password.errors['pattern']
            "
          >
            {{ "validation.pass-char" | translate }}
            <br />
            {{ "validation.pass-eng" | translate }}
            <br />
            {{ "validation.pass-num" | translate }}
            <br />
            {{ "validation.pass-special" | translate }}
            <br />
            {{ "validation.pass-upper" | translate }}
          </small>
          <mat-error *ngIf="capsOn" class="caps-on">
            <mat-icon class="material-icons-outlined">info</mat-icon>
            {{ "validation.caps-lock" | translate }}</mat-error
          >
        </div>

        <div class="form-input">
          <mat-label
            >{{ "login.confirm-pass" | translate }}
            <span class="label__required">*</span>
          </mat-label>
          <mat-form-field
            class="bodytext-normal"
            block
            appearance="fill"
            color="primary"
            [ngClass]="{
              invalid: passConfirmation.touched && passConfirmation.errors
            }"
          >
            <input
              [type]="hideSignup ? 'password' : 'text'"
              name="passConfirmation"
              #passConfirmation="ngModel"
              [(ngModel)]="registerData.passConfirmation"
              matInput
              [comparePassword]="password"
              minlength="8"
              maxlength="50"
              pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[_+#?!@$%^&*-]).{8,}$"
              required
              autocomplete="off"
            />
            <!-- <mat-error>{{ "validation.pass-not-match" | translate }}</mat-error> -->
            <button
              class="toggle-pass bodytext2"
              type="button"
              mat-icon-button
              matSuffix
              (click)="hideSignup = !hideSignup"
              [attr.aria-label]="'Hide password'"
              [attr.aria-pressed]="hideSignup"
              [ngClass]="{ invalid: passConfirmation.errors }"
            >
              <mat-icon class="material-icons-outlined">{{
                hideSignup ? "visibility_off" : "visibility"
              }}</mat-icon>
            </button>
          </mat-form-field>
          <small
            class="mat-error"
            *ngIf="
              !passConfirmation?.valid &&
              passConfirmation.touched &&
              passConfirmation.errors
            "
          >
            {{ "validation.pass-not-match" | translate }}
          </small>
        </div>
      </div>

      <div class="form-footer">
        <button
          type="submit"
          [disabled]="!signUpForm.form.valid"
          mat-raised-button
          color="primary"
          [loading]="loading.login"
          (click)="onSignUpSubmit()"
          class="block main-btn button18"
        >
          {{ "login.new-sign-up" | translate }}
        </button>

        <div class="apps_container">
          <div class="apps_container-or">
            {{ "login.or" | translate }}
          </div>
          <div class="apps">
            <div class="apps-item">
              <figure>
                <img src="../../../assets/images/apple-login.svg" alt="apple" />
              </figure>
            </div>
            <div class="apps-item">
              <figure>
                <img
                  src="../../../assets/images/google-login.svg"
                  alt="google"
                />
              </figure>
            </div>
          </div>
        </div>

        <!-- <p class="subtitle1">
        {{ "login.dont-have-account" | translate }}
        <a class="headline6">
          {{ "login.sign-up" | translate }}
        </a>
      </p> -->

        <!-- <a class="headline6" (click)="resetPassword()">
      {{ "login.forgot-my-pass" | translate }}
    </a> -->
      </div>
    </form>
  </div>

  <!-- guest new password form -->
  <form
    *ngIf="
      guestNewPassForm &&
      browserCompatibility &&
      !openAppUi &&
      !loading.getData &&
      isUDPConnected
    "
    class="login-form"
  >
    <a (click)="closeGuestNewPassForm()" class="back-link bodytext2">
      <mat-icon>arrow_back</mat-icon>
    </a>

    <label class="headline2">{{ "login.change-password" | translate }}</label>
    <p class="bodytext2">
      {{ "login.you-will-recive-your-new-pass" | translate }}
    </p>

    <mat-form-field class="bodytext-normal" appearance="fill">
      <input
        [type]="hide ? 'password' : 'text'"
        placeholder="{{ 'login.sent-code' | translate }}"
        [(ngModel)]="userData.sentCode"
        [ngModelOptions]="{ standalone: true }"
        matInput
        required
        autocomplete="off"
      />
      <mat-error>{{ "validation.reset-code-unvalide" | translate }}</mat-error>
      <button
        class="toggle-pass"
        mat-icon-button
        matSuffix
        (click)="hide = !hide"
        [attr.aria-label]="'Hide password'"
        [attr.aria-pressed]="hide"
      >
        <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
      </button>
    </mat-form-field>

    <div class="login-footer">
      <button mat-raised-button color="primary" class="block main-btn button18">
        {{ "login.reset" | translate }}
      </button>
    </div>
  </form>
</div>
